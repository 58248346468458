
.product-types {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: var(--m);
}

.product-type-header {
    font-weight: 700;
    font-size: var(--text-l);
    color: white;
}

.product-type {
    display: flex;
    flex-direction: column;
    width: 367px;
    text-align: center;
}

.product-type-selected {
    border: 2px solid #00CC88
}

.product-type-icon {
    position: relative;
    float: left;
}

.maturity-selected {
    border: 3px solid red;
}

.product-headers {
    display: grid;
    grid-template-columns: 140px 130px 150px 120px 180px 100px;
    gap: var(--m);
    width: 100%;
}

.product-row {
    display:grid;
    grid-template-columns: 140px 130px 150px 120px 180px 100px;
    gap: var(--m);
    width: 100%;
    height: 68px;
}

.product-headers-put {
    display: grid;
    grid-template-columns: 140px 130px 150px 130px 80px 100px 100px;
    gap: var(--m);
    width: 100%;
}

.product-row-put {
    display: grid;
    grid-template-columns: 140px 130px 150px 130px 80px 100px 100px;
    gap: var(--m);
    width: 100%;
    height: 68px;
}


// product name
.product-column1 {
    grid-column: 1;
}

// est. yield
.product-column2{
    grid-column: 2;
}

.estimated-yield-column {
    color: var(--carribean-green-color);
}

// strike price
.product-column3 {
    grid-column: 3;
}

.maturity-column {
    flex-direction: column;
}

// maturity
.product-column4 {
    grid-column: 4;
}

// deposit
.product-column5 {
    grid-column: 5;
}

//subscription end
.product-column6 {
    grid-column: 6;
}

//subscription end
.product-column7 {
    grid-column: 7;
}


.sp-item {
    width: 220px;
    height: 250px;
    padding: var(--m);
}

.sp-item-graphic {
    height: 50px;
}

.sp-deposit {
    width: 375px;
}

.product-info {
    min-width: 464px;
}

.filters {
    display: flex;
    flex-direction: column;
    flex:1;
}

.filter-striking-price {
    width: 100px;
    border: none;
}

.filter-deposit-currency {
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
}

.filter-maturity-marker {
    width: var(--xl);
}

.filter-maturity-marker-text {
    color: #76808F;
}

.filter-maturity-marker-text-filled {
    color: var(--blue-main-color);
}

.grayscale span {    
    color:#656262;
}

.grayscale img {
    filter: grayscale(100%);
}

// TODO: move these to a separate file?
.subscription-row {
    display: grid;
    grid-template-columns: 140px 130px 150px 150px 130px 130px 130px;
    gap: var(--m);
    width: 100%;
}

.position-row {
    display: grid;
    grid-template-columns: 140px 130px 150px 150px 130px 130px;
    gap: var(--m);
    width: 100%;
}

.product-lifecyle {
    display: grid;
    grid-template-columns: 100px 100px 100px 100px;
    width: 100%;
}

.spot-level {
    position: relative;
    z-index: 0;
}

.spot-level::before {
    content: '';
    position: absolute;
    top: 65%;
    width: 100%;
    height: 2px;
    background-color:green;
    z-index: -1;
}