.pool-name {
    font-weight: 600;
}

.manage-liquidity-button {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    height: 56px;
    width: 56px;
    background-color: var(--blue-main-color);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
    margin-top: 30%;
    margin-left: -30%;
}

.duration-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    column-gap: var(--xs);
    margin-top: var(--s);
}

.duration-marker {
    width: var(--l);
    height: var(--s);
    background-color: #E8EDF6;
    border-radius: var(--s);
}

.duration-marker-filled {
    background-color: var(--blue-main-color);
}

.tab-root {
    text-transform: none;
    width: 320px;

}

.tab-indicator {
    display: none;
}

.tabs {
    align-self: end;
    margin-bottom: 2px;
}

.tab {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 52px;
    padding: 16px;
    color:black;
    border-radius: 16px;
    width: 100%;
    font-size: var(--text-s);
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    background: #FFFFFF;
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.tab-selected {
    background: linear-gradient(91.6deg, #E8EDF6 0%, #F2F5F9 100%);
    box-shadow: inset 1.5px 1.5px 1px rgba(174, 174, 192, 0.2);
}

.pool-headers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: var(--xl);
    margin-bottom: var(--xs);
}

.pool-header {
    font-size: 12px;
    color: var(--exotic-gray);
}