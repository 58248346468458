.currency {
  color: var(--exotic-blue);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  width: 200px;
//   line-height: 48px;
}

.currency-icon {
    width: 24px;
} 

.currency-large {
  font-size: 64px;
  width: auto;
}

.currency-large img {
  width: 65px;
  height: 65px;
}

.currency-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: var(--s);
}

