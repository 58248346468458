// @import url('https://fonts.googleapis.com/css?family=Spartan&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@100;200;300;400;500;600;700;800;900&display=swap');

// you can import third-party (S)CSS from node module packages
// for example to include bulma and make all its classes available,
// simply uncomment the following class name
// @import "bulma";

* {
    font-family: 'Rubik', sans-serif;
    box-sizing: border-box;
    // line-height: normal;
}

:root {
    --background-primary-color: white;
    --background-secondary-color: #F8F8F8;
    --background-tertiary-color: var(--purple);
    --background-black: #181818;
    --exotic-blue: #3B6DF7;
    --exotic-gray: #4F4F4F;
    --exotic-green: #3DD598;
    --low-color: #25C685;
    --medium-color: #FFBC25;
    --high-color: #BC4B51;
    --text-dark: #000;
    --text-light: #828282;
    --text-very-light: #9A9A9A;
    --mint-color-btc: #F2A900;
    --mint-color-usdc: #2775ca;
    --mint-color-eth: #627EEA; 
    --corat: #FB8F67;
    --purple: #5D5FEF;
    --validation-color: #61C9A8;
    --blue-main-color: #3B6DF7;
    --sunglow-color: #FFCF00;
    --red-color: #C4274E;
    --green-color: #03A972;
    --carribean-green-color: #00CC88;
    --jasmine: #FDE588;

    /* Text colors */

    --text-color-primary: var(--purple);
    --text-color-secondary: var(--corat);
    --text-color-light: white;
    /* Text sizes */

    --text-xxxl: 75px;
    --text-xxl: 40px;
    --text-xl: 32px;
    --text-m: 18px;
    --text-s: 16px;
    --text-xs: 14px;
    /* Spacing */
    --xxs: 2px;
    --xs: 4px;
    --s: 8px;
    --m: 16px;
    --l: 32px;
    --xl: 64px;
    --xxl: 128px;
    // 40
    // 80
    /* Border radius */

    --border-x-sharp: 2px;
    --border-sharp: 8px;
    --border-soft: 16px;
    /* Box shadows */

    --shadow-card: 0px 1px 2px rgba(128, 138, 157, 0.12), 0px 8px 32px rgba(128, 138, 157, 0.24);
    --shadow-card-button: -6px -12px 12px 0px #FFFFFF, 0px 4px 4px 0px #00000040;
    /* Borders */

    --border-card-button: 1px solid, linear-gradient(91.6deg, #E8EDF6 0%, #F2F5F9 100%);
}



@media screen and (max-width: 600px) {
    :root {
        --text-xxxl: 55px;
        --text-xxl: 32px;
        --text-xl: 26px;
        --text-m: 16px;
        --text-s: 14px;
        --text-xs: 12px;
    }
}

body {
    padding: 0;
    margin: 0;
    background-color: var(--background-primary-color);
}

p {
    margin: var(--m) 0;
}

img {
    max-width: 100%;
}

.section {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.content {
    width: 100%;
    max-width: 1200px;
    position: relative;
}

.parent {
    width: 100%;
    display: grid;
}

.main-content {
    min-height: 1000px;
}

// Reset button style + Support custom button colors
// Sources:
//          https://stackoverflow.com/questions/65297617/how-to-make-button-background-color-a-little-darker-on-hover-using-css
//          https://css-tricks.com/overriding-default-button-styles/

button {
    display: inline-block;
    border: none;
    padding: 0.5rem 1rem;
    margin: 0;
    text-decoration: none;
    --hue: 712;
    --saturation: 100%;
    --lightness: 60%;
    background-color: hsl(var(--hue), var(--saturation), var(--lightness));
    color: #ffffff;
    font-family: sans-serif;
    font-size: 1rem;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, 
                transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
    outline-offset: 0;
    font-family: Rubik;
}

button:not([disabled]):hover,
button:not([disabled]):focus {
    --lightness: 40%;
    outline-offset: 0;
}

button:focus {
}

button:not([disabled]):active {
    // transform: scale(0.99);
    --lightness: 30%;
}

// Example of custom override:
.mybutton {
    --hue: 612;
}



// Custom (project-wide) button styling
.button {
    border-radius: 12px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--xs);
    line-height: normal;
}

.row-button {
    --hue: 0;
    --saturation: 0%;

    height: 100%;
    border-radius: 0 20px 20px 0;
    font-weight: 600;
    width: 118px;
}

%button {
    font-size: var(--text-m);
    font-weight: 400;
    text-transform: none;
    height: 52px;
    width: 100%;
    //max-width: 370px;
    border-radius: var(--border-soft);
    transition: all 0.3s ease;
}

.button-primary {
    @extend %button;
    background-color: #000000;
    color: #FFFFFF;
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.button-primary:not([disabled]):hover {
    background: linear-gradient(90deg, #000000 0%, #555555 100%);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.button-primary:not([disabled]):active {
    background: #555555;
    box-shadow: inset -4px -4px 8px #474747, inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.button-primary:disabled, button:disabled {
    pointer-events: none;
    background-color: #666;
}

.button-dropdown {
    @extend %button;
    background: #FFFFFF;
    color: #000000;
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
    border: 1px solid #E8EDF6;
}

.button-dropdown:hover {
    background: linear-gradient(90deg, #FFFFFF 1.72%, #EBEBEB 75.94%);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5)
} 

.button-dropdown:active, .button-dropdown-active {
    background: #EBEBEB;
    box-shadow: inset -4px -4px 8px #E2E2E2, inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

/*.button-yield {
    @extend %button;
    background: linear-gradient(90.09deg, #BE89FA 64.56%, #976DC7 99.92%);
    color: #000000;
    border: 1px solid #A074D3;
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.button-yield:hover {
    background: linear-gradient(90.09deg, #B182E7 0.08%, #996FCA 99.92%);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
} 

.button-yield:active {
    background: #996FCA;
    box-shadow: inset -4px -4px 8px #895EBC, inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}*/

.button-solid-purple {
    @extend %button;
    background: #AB11F4;
    border: 1px solid #75483E;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -6px -12px 12px #FFFFFF;
}

.button-solid-purple:hover {
    background: #A11FEE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -6px -12px 12px #FFFFFF;
}

.button-solid-purple:active {
    background: #A11FEE;
    box-shadow: inset -4px -4px 8px #8E1FCC, inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.button-solid-blue {
    @extend %button;
    background: #2E3FBF;
    border: 1px solid 1px solid #1B2572;;
    color: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -6px -12px 12px #FFFFFF;
}

.button-solid-blue:hover {
    background: #2E3FBF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), -6px -12px 12px #FFFFFF;
}

.button-solid-blue:active {
    background: #2E3FBF;
    box-shadow: inset -4px -4px 8px #1B2572, inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.button-option, .button-yield {
    @extend %button;
    color: #000000;
    background: linear-gradient(90deg, rgba(254, 231, 153, 0.99) 66.15%, #FBD7A2 100%);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.button-option:hover, .button-yield:hover {
    background: linear-gradient(90deg, rgba(241, 219, 145, 0.99) 0%, #FBD7A2 100%);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.button-option:active, .button-yield:active {
    /*background: #FBD7A2;*/
    box-shadow: inset -4px -4px 8px #EEC78E, inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

.button-positive {
    @extend %button;
    color:#000000;
    background: linear-gradient(90deg, #A6FA7D 66.15%, #66E368 100%);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.button-positive:hover {
    background: linear-gradient(90deg, #A6FA7D 0%, #66E368 100%);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.button-positive:active {
    background: linear-gradient(90deg, #66E368 0%, #66E368 100%);
    box-shadow: inset 4px 4px 4px rgba(0, 0, 0, 0.25), inset -4px -4px 8px #3ED740;
}

.button-negative {
    @extend %button;
    color: #000000;
    background: linear-gradient(90deg, #FA9164 70.31%, #FF5959 100%);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.button-negative:hover {
    background: linear-gradient(90deg, #FA9164 0%, #FF5959 100%);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
}

.button-negative:active {
    background: linear-gradient(90deg, #FD6E5C 0%, #FD6E5C 100%);
    box-shadow: inset -4px -4px 8px #EA5F4B, inset 4px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Standard headers and texts */


h4 {
    margin-block-start: 1.83em;
    margin-block-end: 1.13em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;

    font-size: 20px;
    font-weight: 700;
}

.sub-text {
    font-size: 14px;
    color: var(--text-light);
    margin-bottom: 10px;
}

.header-1 {
    color: var(--text-color-primary);
    font-size: var(--text-xxl);
    font-weight: 700;
}

.header-1-light {
    color: var(--text-color-light);
    font-size: var(--text-xxl);
    font-weight: 700;
}

.header-2 {
    color: var(--text-color-secondary);
    font-size: var(--text-xl);
    font-weight: 700;
}

.header-3 {
    color: var(--text-color-secondary);
    font-size: var(--text-m);
    font-weight: 700;
}

.header-4 {
    color: var(--text-very-light);
    font-size: var(--text-m);
    font-weight: 300;
}

.header-5 {
    color: var(--text-dark);
    font-size: var(--text-xl);
    font-weight: 500;
}

.header-6 {
    color: var(--sunglow-color);
    font-weight: 500;
    font-size: var(--text-xl);
}

.text {
    font-size: var(--text-s);
}

.text-grey {
    font-size: var(--text-m);
    color: var(--text-very-light);
    font-weight: 300;
}


.text-light {
    color: var(--text-color-light);
    font-size: var(--text-s);
}

.text-error {
    color: var(--high-color);
    font-size: var(--text-s);
}

.text-color-usdc {
    color: #3E73C4;
}

.text-color-btc {
    color: #F7931A;
}

.text-color-eth {
    color: var(--mint-color-eth)
}

.text-color-black {
    color: #000000;
}

.text-color-ray {
    color: var(--purple)
}

.text-color-sol {
    color: #000000
}

.text-color-srm {
    color: lightblue
}

// TODO : Move styles (tab and wallet) to another file

.tab-panel {
    padding: 0;
}

.tab-root {
  text-transform: none;
  font-size: 20px;
  border-bottom: 2px solid #4F4F4F;;
}

.tab-selected {
  color: var(--exotic-blue);
}

#wallet-modal .MuiPaper-root {
  color: #000000;
  background-color: var(--background-primary-color);
  font-weight: bold;
  font-family: Iter;
  border-radius: 25px;
  padding-bottom: 32px;
  min-width: 344px;
  width: 368px;
}

@media screen and (max-width: 600px) {
  #wallet-modal .MuiPaper-root {
      width: 344px;
  }
}

#wallet-modal .MuiDialogTitle-root {
  background-color: var(--background-secondary-color);
}

#wallet-modal h2 {
    font-weight: bold;
    justify-content: center;
}

#wallet-modal .MuiList-root {
  background-color: var(--background-primary-color);
}

#wallet-modal .MuiListItem-root {
  background-color: var(--background-primary-color);
}

#wallet-modal .MuiButton-label {
   color:#000000;
   text-transform: none;
   border: 1px solid #1A3B34;
   border-radius: 12px;
   padding-left: 32px;
   padding-right: 15px;
   height: 50px
}

#wallet-modal .MuiButton-root {
  background: 1px solid #1A3B34;
}

#wallet-menu ul {
  min-width: 300px;
}


/* Mui overrides */

.MuiStepLabel-iconContainer {
    padding: 0;
}

.MuiStepper-root {
    padding: 0;
}

.MuiStepper-root > .MuiStep-root:first-child {
    padding-left: 0;
}

.MuiStepper-root > .MuiStep-root:last-child {
    padding-right: 0;
}

.MuiStepIcon-root {
    width: 1.4em;
    height: 1.4em;
    font-weight: 600;
}

.MuiStepIcon-root.MuiStepIcon-completed, .MuiStepIcon-root.MuiStepIcon-active {
    color: var(--exotic-blue);
}

.MuiAlert-standardWarning {
    background-color: #ffebd1;
}

.MuiOutlinedInput-root {
    border-radius: var(--border-soft);
}


// TODO : This has been done very quickly to be ready for the demo. Do recharts styling differently? Move to Plotly?

.recharts-wrapper text tspan {
    font-size: 13px;
}

.single-input-form {
    display: inline-flex;
    width: fit-content;
    height: 52px;
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.4);
    border-radius: var(--border-soft);
    font-size: var(--text-m);
}

@media screen and (max-width: 600px) {
    .single-input-form {
        display: flex;
        flex-direction: column;
        row-gap: var(--m);
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        height: auto;
    }
}
    
.single-input-form input {
    height: 100%;
    border: none;
    border: 1px solid #E8EDF6;
    border-right: none;
    border-radius: var(--border-soft) 0 0 var(--border-soft);
    padding: var(--m);
    min-width: 260px;
}

@media screen and (max-width: 600px) {
    .single-input-form input {
        width: 100%;
        max-width: 370px;
        border-radius: var(--border-soft);
    }
}
    
.single-input-form button {
    height: 100%;
    background-color: black;
    border: 1px solid black;
    border-left: none;
    border-radius: 0 var(--border-soft) var(--border-soft) 0;
    text-align: center;
    padding: var(--xxs) var(--l);
    transition: all 0.3s ease;
    min-height: 50px;
}

@media screen and (max-width: 600px) {
    .single-input-form button {
        width: 100%;
        border-radius: var(--border-soft);
    }
}

.h-divider {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 0.5px solid #e2e2e2;
}

.v-divider {
    display: inline;
    margin-left: 20px;
    margin-right: 20px;
    border-left: 0.5px solid #e2e2e2;
}

.card {
    box-shadow: var(--shadow-card);
    border-radius: var(--border-soft);
    padding: var(--m);
    background-color: var(--background-primary-color);
}

.card-button {
    box-shadow: var(--shadow-card-button);
    border: var(--border-card-button);
    border-radius: var(--border-soft);
    border: 1px solid #E8EDF6;
    cursor: pointer;
}

.card-button:hover {
    box-shadow: -6px -12px 12px 0px #FFFFFF, 0px 4px 4px 1px #00000040;
}

.progress-white {
    color: #fff;
}

.progress-button {
    margin-left: 20px;
    width: 20px !important;
    height: 20px !important;
}

.section-title {
    color: var(--text-very-light);
    font-size: var(--text-s);
    font-weight: 300;
}

.go-button {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: row;
    height: 56px;
    width: 56px;
    background-color: var(--blue-main-color);
    box-shadow: -1px -1px 3px #FFFFFF, 1.5px 1.5px 3px rgba(174, 174, 192, 0.5);
    border-radius: var(--border-soft);
}

.lifecyle-dot {
  height: 25px;
  width: 25px;
  background-color: #323135;
  border-radius: 50%;
}

.lifecyle-dot-blue {
  background: linear-gradient(91.6deg, #1450F5 0%, #3B6DF7 100%);
}